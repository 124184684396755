
import { Options, Vue } from 'vue-class-component'
import AdminSponsors from '@/components/Sponsors/AdminSponsors/index.vue' // @ is an alias to /src

@Options({
  components: {
    AdminSponsors
  }
})
export default class SponsorsView extends Vue {}
