import { Options, Vue } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
import { APIURL } from '@/components/Utility/APIBase'
import BaseFunctions, { defaultMissingImgURL, LogoObj, mediaBaseURL } from '@/components/Utility/Base'
import SponsorsDataService from '@/services/SponsorsDataService'
import SysSponsor from '@/types/SysSponsor'
import MediasDataService from '@/services/MediasDataService'
import CommonFunctions from '@/components/Utility/Common'

type sponsorDataType = { sponsorForside: boolean; sponsorStatus: boolean; sponsorNavn: string; sponsorLogo: string; sponsorURL: string; }
type dataReturnType = { APIURL: string; sponsors: any; medias: any; imageModal: boolean; selectedSponsorImage: any; editImageModal: boolean; tabValue: string; error: any; }

@Options({
  props: {
    msg: String
  }
})
export default class AdminSponsors extends Vue {
  msg!: string
  tabValue = 'Vis og rediger sponsorer'
  private sponsors: SysSponsor[] = []
  private tempSponsor = {} as SysSponsor
  error: any = null
  selectedFile: any = null
  sponsorData: sponsorDataType = { sponsorForside: false, sponsorStatus: true, sponsorNavn: '', sponsorLogo: '', sponsorURL: '' }
  filterStatusStringValue = 'Vis alt'
  filterStatusStringOptions: string[] = ['Vis alt', 'Aktive sponsorater', 'Inaktive sponsorater']
  sponsorSearchInputValue = ''
  sponsorSearchTerm = ''
  private currentListSortingOrder = ''
  lastCreatedSponsorId = 0
  lastCreatedMediaId = 0
  private submitted = false
  private editSponsorModal = false
  private imageModal = false
  private editImageModal = false
  private lastEditedSponsorId = 0
  private medias: any[] = []
  selectedSponsorImage = ''
  frontPage = 0
  private deleteSponsorWarningModal = false
  private deleteSponsorId = 0

  readonly name : string = 'AdminSponsors'
  $Message: any

  data (): dataReturnType {
    return {
      APIURL: APIURL,
      sponsors: this.sponsors,
      medias: this.medias,
      imageModal: false,
      selectedSponsorImage: '',
      editImageModal: false,
      tabValue: 'Vis og rediger sponsorer',
      error: this.error
    }
  }

  @Watch('filterStatusStringValue')
  onSponsorStatusChanged (statusValue: string, oldStatusValue: string) : void {
    console.log('Filter status changed to: ' + statusValue)
    this.retrieveSponsors()
  }

  @Watch('editSponsorModal')
  onEditSponsorModalToggleChanged (toggleValue: boolean, oldToggleValue: boolean) : void {
    if (toggleValue === false) {
      this.sponsorData = { sponsorForside: false, sponsorNavn: '', sponsorURL: '', sponsorStatus: true, sponsorLogo: '' }
    }
  }

  @Watch('deleteSponsorWarningModal')
  onDeleteSponsorWarningChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.deleteSponsorId = 0
    }
  }

  public useSelectedImage () : void {
    if (this.selectedSponsorImage !== undefined && this.selectedSponsorImage) {
      this.sponsorData.sponsorLogo = this.selectedSponsorImage
      this.imageModal = false
      this.editImageModal = false
    }
  }

  public getOnlyImages () : any {
    return this.medias
      .filter((element) => { return (element.mime === 'image/jpeg' || element.mime === 'image/png' || element.mime === 'image/webp') })
  }

  public retrieveMedias () : void {
    console.log('Fetching media files from API.')
    MediasDataService.getAll()
      .then((response: any) => {
        this.medias = response.data
        console.log(response.data)
        this.forceRerenderMediaList()
        this.$forceUpdate()
      })
      .catch((err: any) => {
        this.error = err
      })
  }

  public onFileSelected (event: any) : void {
    this.selectedFile = event.target.files[0]
    console.log(this.selectedFile)
  }

  public onUpload () : void {
    if (this.selectedFile !== null && (this.selectedFile.type === 'image/jpeg' || this.selectedFile.type === 'image/png' || this.selectedFile.type === 'image/webp')) {
      const fd: FormData = new FormData()
      fd.append('files', this.selectedFile)
      MediasDataService.upload(fd)
        .then((response) => {
          this.lastCreatedMediaId = response.data.id
          console.log(response.data)
          console.log('Ref id: ' + response.data.id)
          alert('Billedet succesfuldt uploadet')
          this.retrieveMedias()
        })
        .catch((err) => {
          this.error = err
          alert('Ups et eller andet gik galt')
        })
    } else {
      alert('Den valgte fil kan desvære ikke uploades')
    }
  }

  public cancelImage () : void {
    this.selectedSponsorImage = ''
  }

  public countFrontPageSponsor () : number {
    this.frontPage = 0
    for (const sponsorItem of this.sponsors) {
      if (sponsorItem.sponsor_forside === true) {
        this.frontPage += 1
      }
      console.log('number of frontpage items is ' + this.frontPage)
    }
    return this.frontPage
  }

  public extractLogoURLFromSponsorLogoData (sponsorLogoData: any) : string {
    let logoURL = defaultMissingImgURL
    console.log(sponsorLogoData)

    if (sponsorLogoData !== null) {
      // console.log('String output of klubber_logo data = ' + JSON.stringify(clubLogoData))
      // const logoObj = BaseFunctions.setLogoMediaObjFromStr(JSON.stringify(clubLogoData)) as LogoObj[]
      const logoObj = BaseFunctions.setLogoMediaObjFromObj(<any> sponsorLogoData) as LogoObj[]
      console.log(logoObj)
      // logoURL = BaseFunctions.setLogoMediaObjFromStr(JSON.stringify(clubLogoData))[0].url
      logoURL = logoObj[0] === undefined || logoObj[0].url === null ? logoURL : mediaBaseURL + logoObj[0].url
      // console.log('Test current club logo media URL: ' + mediaBaseURL + logoURL)
    }

    return logoURL
  }

  public forceRerenderSponsorsList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  public forceRerenderMediaList (): void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  public findSponsor () : void {
    console.log('Searching sponsors.')
    this.sponsorSearchTerm = this.sponsorSearchInputValue
    this.retrieveSponsors()
  }

  public resetSort () : void {
    this.currentListSortingOrder = ''
    this.sponsorSearchInputValue = ''
    this.sponsorSearchTerm = ''
    this.retrieveSponsors()
  }

  public editSponsor (sponsorId : number) : void {
    console.log('Edit sponsor with Id: ' + sponsorId)
    this.editSponsorModal = true
    this.lastEditedSponsorId = sponsorId

    SponsorsDataService.get(sponsorId.toString())
      .then((response) => {
        this.tempSponsor = response.data
        console.log(response.data)
        this.sponsorData = { sponsorForside: this.tempSponsor.sponsor_forside, sponsorNavn: this.tempSponsor.sponsor_sponsornavn, sponsorStatus: this.tempSponsor.sponsor_status, sponsorURL: this.tempSponsor.sponsor_link, sponsorLogo: (this.tempSponsor.sponsor_billede !== undefined ? this.tempSponsor.sponsor_billede : '') }
      })
      .catch((err) => {
        this.error = err
      })
  }

  public cancelSponsorEdit () : void {
    console.log('Cancel sponsor edit')
    this.sponsorData = { sponsorForside: false, sponsorNavn: '', sponsorStatus: true, sponsorURL: '', sponsorLogo: '' }
  }

  public deleteSponsorWarning (sponsorId: number) : void {
    this.deleteSponsorId = sponsorId
    this.deleteSponsorWarningModal = true
  }

  public deleteSponsor (sponsorId : number) : void {
    console.log('Delete sponsor with Id: ' + sponsorId)
    SponsorsDataService.delete(sponsorId.toString())
      .then((response) => {
        console.log(response.data)
        this.retrieveSponsors()
        this.deleteSponsorWarningModal = false
      })
      .catch((err) => {
        this.error = err
      })
  }

  public createSponsor () : void {
    const imageTest = JSON.stringify(this.sponsorData.sponsorLogo)
    if (this.sponsorData.sponsorNavn.length > 1 && (imageTest.includes('"mime":"image/jpeg') || imageTest.includes('"mime":"image/png"') || imageTest.includes('"mime":"image/webp"'))) {
      const createSponsorData = {
        sponsor_status: true,
        sponsor_sponsornavn: this.sponsorData.sponsorNavn,
        sponsor_link: this.sponsorData.sponsorURL,
        sponsor_billede: this.sponsorData.sponsorLogo
      }
      console.log('Attempt to create new sponsor with Id: ' + (1 + this.lastCreatedSponsorId))

      SponsorsDataService.create(createSponsorData)
        .then((response) => {
          this.lastCreatedSponsorId = response.data.id
          console.log(response.data)
          this.submitted = true
        })
        .catch((err) => {
          this.error = err
        })
        .then(() => {
          this.$Message.success({ text: 'Sponsoren er oprettet' })
          this.newCreateSponsor()
          this.tabValue = 'Vis og rediger sponsorer'
        })
    }
  }

  public updateSponsor () : boolean {
    const imageTest = JSON.stringify(this.sponsorData.sponsorLogo)
    if (this.sponsorData.sponsorNavn.length > 1 && (imageTest.includes('"mime":"image/jpeg') || imageTest.includes('"mime":"image/png"') || imageTest.includes('"mime":"image/webp"'))) {
      const updateSponsorData = {
        sponsor_sponsornavn: this.sponsorData.sponsorNavn,
        sponsor_link: this.sponsorData.sponsorURL,
        sponsor_billede: this.sponsorData.sponsorLogo,
        sponsor_status: this.sponsorData.sponsorStatus
      }
      SponsorsDataService.update(this.lastEditedSponsorId.toString(), updateSponsorData)
        .then((response) => {
          console.log(response.data)
          this.sponsorData = { sponsorForside: false, sponsorNavn: '', sponsorURL: '', sponsorLogo: '', sponsorStatus: true }
          this.retrieveSponsors()
        })
        .catch((err) => {
          this.error = err
          console.log(err)
          this.sponsorData = { sponsorForside: false, sponsorNavn: '', sponsorURL: '', sponsorLogo: '', sponsorStatus: true }
        })
      this.editSponsorModal = false
      return true
    }
    return false
  }

  public newCreateSponsor () : void {
    this.submitted = false
    this.sponsorData = { sponsorForside: false, sponsorStatus: true, sponsorNavn: '', sponsorURL: '', sponsorLogo: '' }
    this.retrieveSponsors()
  }

  public frontPageSponsor (sponsorId : number) : void {
    console.log('Change sponsorfrontpage for id: ' + sponsorId)
    this.countFrontPageSponsor()
    let updateSponsorData: any = {}
    SponsorsDataService.get(sponsorId.toString())
      .then((response) => {
        this.tempSponsor = response.data
        console.log(response.data)

        if (this.tempSponsor.sponsor_forside === true) {
          updateSponsorData = {
            sponsor_forside: !this.tempSponsor.sponsor_forside
          }
        } else {
          if (this.frontPage < 4) {
            updateSponsorData = {
              sponsor_forside: !this.tempSponsor.sponsor_forside
            }
          } else {
            alert('Der kan højst være fire sponsorer på forsiden.\nFjern en af dem og prøv igen')
            return
          }
        }

        console.log('New Frontpage status = ' + updateSponsorData.sponsor_forside)

        SponsorsDataService.update(sponsorId.toString(), updateSponsorData)
          .then((response) => {
            console.log(response.data)
            const sponsorsIndexToUpdateForside = this.sponsors.findIndex(x => x.id === sponsorId)
            this.sponsors[sponsorsIndexToUpdateForside].sponsor_forside = updateSponsorData.sponsor_forside
          })
          .catch((err) => {
            this.error = err
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public blockSponsor (sponsorId : number) : void {
    console.log('Change status of sponsor with Id: ' + sponsorId)

    SponsorsDataService.get(sponsorId.toString())
      .then((response) => {
        this.tempSponsor = response.data
        console.log(response.data)

        const updateSponsorData = {
          sponsor_status: !this.tempSponsor.sponsor_status
        }

        console.log('New status of sponsor = ' + updateSponsorData.sponsor_status)

        SponsorsDataService.update(sponsorId.toString(), updateSponsorData)
          .then((response) => {
            console.log(response.data)
            const sponsorsIndexToUpdateStatus = this.sponsors.findIndex(x => x.id === sponsorId)
            this.sponsors[sponsorsIndexToUpdateStatus].sponsor_status = updateSponsorData.sponsor_status
          })
          .catch((err) => {
            this.error = err
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public retrieveSponsors () : void {
    console.log('Fetching sponsor data from API.')
    if (this.sponsorSearchTerm.length > 0) {
      SponsorsDataService.findBySearchTerm((this.filterStatusStringValue === 'Aktive sponsorater'), (this.filterStatusStringValue === 'Vis alt'), this.sponsorSearchTerm, this.currentListSortingOrder)
        .then((response) => {
          this.sponsors = response.data
          this.forceRerenderSponsorsList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
        })
    } else {
      if (this.filterStatusStringValue === 'Vis alt') {
        SponsorsDataService.getAll(this.currentListSortingOrder)
          .then((response) => {
            this.sponsors = response.data
            this.forceRerenderSponsorsList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
          })
      } else {
        SponsorsDataService.findBySponsorStatus((this.filterStatusStringValue === 'Aktive sponsorater'), this.currentListSortingOrder)
          .then((response) => {
            this.sponsors = response.data
            this.forceRerenderSponsorsList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
          })
      }
    }
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

      if (userDataObject !== null && userDataObject.id !== 1 && userDataObject.id < 100 && userDataObject.role !== undefined && userDataObject.role !== null && userDataObject.role.id === 5) {
        this.retrieveSponsors()
        this.retrieveMedias()
      } else {
        CommonFunctions.redirectLogin()
      }
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
